export const Icons = {
  logo: require("./logo.png"),
  emailDark: require("./email-dark-icon.png"),
  phoneDark: require("./phone-dark-icon.png"),
  phoneLight: require("./phone-light-icon.png"),
  phonePink: require("./phone-pink-icon.png"),
  whatsappDark: require("./whatsapp-dark-icon.png"),
  mapDark: require("./map-dark-icon.png"),
  cable: require("./cable-icon.png"),
  remote: require("./remote-icon.png"),
  ce: require("./ce-icon.png"),
  system: require("./system-icon.png"),
  charge: require("./charge-icon.png"),
  home: require("./home-icon.png"),
  menu: require("./menu-icon.png"),
  message: require("./message-icon.png"),
  instagram: require("./instagram-icon.png"),
  pinterest: require("./pinterest-icon.png"),
  linkedin: require("./linkedin-icon.png"),
  flower: require("./flower-icon.png"),
  tools: require("./tools-icon.png"),
  cableDark: require("./cable-dark-icon.png"),
};

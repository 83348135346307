import React from 'react';
import './App.css';
import PageRoutes from './routes/router';
import moment from 'moment';
import 'moment/locale/id'

moment.locale('id')
const App = () => {
  return (
    <div>
      <PageRoutes></PageRoutes>
    </div>
  );
}

export default App;

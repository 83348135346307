import { Stack, Typography } from "@mui/material"
import { isMobile, isTablet } from "react-device-detect"
import { Link } from "react-router-dom"
import { Icons } from "../../assets/icons"
import { BoxShadow, CENTER } from "../../utils/styles"
import { footerContact, footerMenu, footerSosmed } from "./data"

export const Footer = () => {
    return (
        <div style={{ width: '100%', ...CENTER, ...BoxShadow }}>
            <Stack direction={'column'} gap={3} width={'90%'} paddingY={5}>
                <Stack direction={isMobile && !isTablet ? 'column' : 'row'} gap={isMobile && !isTablet ? 5 : isTablet ? 3 : 10} alignItems={'flex-start'} flexWrap={isTablet ? 'wrap' : 'nowrap'} justifyContent={'space-between'}>
                    <Stack direction={'column'} gap={2} alignItems={'flex-start'} width={isMobile === !isTablet ? '100%' : isTablet ? '30%' : '20%'}>
                        <img src={Icons.logo} style={{ height: 'auto', width: isMobile === !isTablet ? '60%' : '80%', objectFit: 'contain' }} alt="" />
                        <Typography variant="body1" color={'#000'} fontWeight={'500'} whiteSpace={isMobile === !isTablet === true && isTablet === false ? 'none' : 'pre-line'}>{'©️ 2023, Swiftlift.\nAll Right Reserved.'}</Typography>
                    </Stack>
                    <Stack direction={'column'} gap={1} alignItems={'flex-start'} width={isMobile === !isTablet ? '100%' : isTablet ? '30%' : '20%'}>
                        <Typography variant="h6" color={'#000'} fontWeight={'600'}>Menu</Typography>
                        {footerMenu.map((item, index) => (
                            <Link to={item.navigate} key={index} style={{ textDecoration: 'none' }}>
                                <Typography variant="body1" color={'#000'}>{item.label}</Typography>
                            </Link>
                        ))}
                    </Stack>
                    <Stack direction={'column'} gap={1} alignItems={'flex-start'} width={isMobile === !isTablet ? '100%' : isTablet ? '30%' : '20%'}>
                        <Typography variant="h6" color={'#000'} fontWeight={'600'}>Sosial Media</Typography>
                        {footerSosmed.map((item, index) => (
                            <Typography variant="body1" key={index} color={'#000'}>{item.label}</Typography>
                        ))}
                    </Stack>
                    <Stack direction={'column'} gap={1} alignItems={'flex-start'} width={isMobile === !isTablet ? '100%' : isTablet ? '50%' : '30%'}>
                        <Typography variant="h6" color={'#000'} fontWeight={'600'}>Contact</Typography>
                        {footerContact.map((item, index) => (
                            <Stack direction={'row'} alignItems={index === 0 ? 'flex-start' : 'center'} gap={1} key={index}>
                                <img src={item.icon} style={{ width: 20, height: 20, objectFit: 'contain' }} alt="" />
                                <Typography variant="body1" key={index} color={'#000'}>{item.label}</Typography>
                            </Stack>
                        ))}
                    </Stack>
                </Stack>
            </Stack>
        </div >
    )
}
import React from "react";
import Page from "./routerTitle";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { RouterInit } from "../utils/routerInit";

import HomeScreen from "../pages/home/home";
import AboutScreen from "../pages/about/about";
import SwiftProScreen from "../pages/swiftPro/swiftPro";
import SwiftLiteScreen from "../pages/swiftLite/swiftLite";
import ContactScreen from "../pages/contact/contact";
import MaintenanceScreen from "../pages/utils/maintenance";
import Blank from "../pages/blank/blank";

const PageRoutes = () => {
    return (
        <Router>
            <RouterInit />
            <Routes>
                <Route path="*" element={<Page title="Error | Swiftlift"><MaintenanceScreen /></Page>} />
                <Route path="/" element={<Page title="Website Not Available"><Blank /></Page>} />
                {/* <Route path="/" element={<Page title="Home | Swiftlift"><HomeScreen /></Page>} />
                <Route path="/about-us" element={<Page title="About Us | Swiftlift"><AboutScreen /></Page>} />
                <Route path="/swift-pro" element={<Page title="Swift Pro | Swiftlift"><SwiftProScreen /></Page>} />
                <Route path="/swift-lite" element={<Page title="Swift Lite | Swiftlift"><SwiftLiteScreen /></Page>} />
                <Route path="/contact" element={<Page title="Contact | Swiftlift"><ContactScreen /></Page>} /> */}
            </Routes>
        </Router>
    );
};

export default PageRoutes;
import { Icons } from "../../assets/icons";

export const Hrefs = [
  { id: 1, label: "Home", navigate: "/" },
  { id: 2, label: "About Us", navigate: "/about-us" },
  { id: 3, label: "SWIFT Pro", navigate: "/swift-pro" },
  { id: 4, label: "SWIFT Lite", navigate: "/swift-lite" },
  { id: 5, label: "Contact", navigate: "/contact" },
];

export const footerMenu = [
  { label: "Home", navigate: "/" },
  { label: "About Us", navigate: "/about-us" },
  { label: "SWIFT Pro", navigate: "/swift-pro" },
  { label: "SWIFT Lite", navigate: "/swift-lite" },
  { label: "Contact", navigate: "/contact" },
];

export const footerSosmed = [
  { label: "Facebook", navigate: "" },
  { label: "Instagram", navigate: "" },
  { label: "Linked In", navigate: "" },
  { label: "Pinterest", navigate: "" },
  { label: "Twitter", navigate: "" },
];

export const footerContact = [
  {
    icon: Icons.mapDark,
    label: "Styrmansgatan 14D, 114 54 Stockholm, Sweden Reg.no 559190-7737",
    navigate: "",
  },
  { icon: Icons.emailDark, label: "email@email.com", navigate: "" },
  { icon: Icons.phoneDark, label: "021-45840793", navigate: "" },
  { icon: Icons.whatsappDark, label: "+62 891-9090-9090", navigate: "" },
];

export const Images = {
  underDev: require("./under-dev.png"),
  homeTools: require("./home-tools.png"),
  homeImage1: require("./home-image1.png"),
  homeImage2: require("./home-image2.png"),
  homeImage3: require("./home-image3.png"),
  headerImage1: require("./header-image1.png"),
  headerImage2: require("./header-image2.png"),
  headerImage3: require("./header-image3.png"),
  aboutImage1: require("./about-image1.png"),
  aboutImage2: require("./about-image2.png"),
  aboutImage3: require("./about-image3.png"),
  aboutImage4: require("./about-image4.png"),
  aboutImage5: require("./about-image5.png"),
  swiftProImage1: require("./swiftpro-image1.png"),
  swiftProImage2: require("./swiftpro-image2.png"),
  swiftProImage3: require("./swiftpro-image3.png"),
  swiftProImage4: require("./swiftpro-image4.png"),
  swiftProImage5: require("./swiftpro-image5.png"),
  swiftProContent: require("./swiftpro-content.png"),
  swiftProSize1: require("./swiftpro-size1.png"),
  swiftProSize2: require("./swiftpro-size2.png"),
  swiftProSize3: require("./swiftpro-size3.png"),
  swiftProSize4: require("./swiftpro-size4.png"),
  swiftLiteImage1: require("./swiftlite-image1.png"),
  swiftLiteImage2: require("./swiftlite-image2.png"),
};

import React from 'react'
import { Icon, Stack, Toolbar, Typography } from '@mui/material'
import NavigationBar from '../../components/navigation/navigationBar';
import { BoxShadow, CENTER } from '../../utils/styles';
import { Colors } from '../../utils/colors';
import { Images } from '../../assets/images';
import { Footer } from '../../components/navigation/footer';
import { isMobile, isTablet } from 'react-device-detect';

const MaintenanceScreen = () => {
    return (
        <div style={{ width: '100%', backgroundColor: '#fff' }}>
            <NavigationBar nav={0} />
            <Toolbar />
            <div style={{ width: '100%', ...CENTER }}>
                <div style={{ width: isMobile ? '90vw' : '40vw', ...BoxShadow }}>
                    <Stack direction={'column'} gap={2} alignItems={'center'} padding={3}>
                        <Stack direction={'row'} alignItems={'center'} gap={2}>
                            <Icon fontSize={'large'} sx={{ color: Colors.error }}>error</Icon>
                            <Typography variant="h6" color={Colors.error}><b>Information</b></Typography>
                        </Stack>
                        <img
                            src={Images.underDev}
                            style={{
                                width: '100%',
                                aspectRatio: isMobile === true && isTablet === false ? 3 / 4 : isTablet ? 4 / 3 : 16 / 9,
                                objectFit: 'cover',
                                objectPosition: isMobile ? 'left' : 'center'
                            }}
                            alt=""
                        />
                        <Typography variant={isMobile ? 'h5' : "h6"} color={'#000'} textAlign={'center'}><b>This Website is Under Development</b></Typography>
                    </Stack>
                </div>
            </div>
            <Toolbar />
            <Toolbar />
            <Footer />
        </div >
    )
}

export default MaintenanceScreen;
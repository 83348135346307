import * as React from "react";
import {
    AppBar,
    Stack,
    Toolbar,
    Icon,
    CssBaseline,
    Drawer,
    IconButton,
    Button,
    Typography,
} from "@mui/material";
import { isMobile, isTablet } from "react-device-detect";
import { useNavigate } from "react-router";
import { BoxShadow, CENTER } from "../../utils/styles";
import { Icons } from "../../assets/icons";
import { Hrefs } from "./data";
import { Colors } from "../../utils/colors";

const NavigationBar = (param: { nav: number }) => {
    const navigate = useNavigate();
    const [isDrawer, setDrawer] = React.useState(false);

    const toggleDrawer = () => {
        setDrawer(!isDrawer)
    };

    const Navs = (e: any, item: any) => {
        navigate(item.navigate)
    }

    return (
        <>
            <CssBaseline />
            <AppBar
                elevation={0}
                position={param.nav === 1 ? "fixed" : "sticky"}
                sx={{
                    backgroundColor: '#ffffff',
                    width: '100%',
                    top: 0,
                    transition: 'all .3s',
                    ...BoxShadow
                }}
            >
                <Toolbar sx={{ ...CENTER }}>
                    {
                        isMobile === true || isTablet === true ?
                            <Stack spacing={2} width={'100%'}>
                                <Stack
                                    width={'100%'}
                                    direction={"row"}
                                    alignItems={"center"}
                                    justifyContent={"space-between"}
                                >
                                    <img src={Icons.logo} onClick={() => navigate('/')} style={{ height: 40, width: 'auto', objectFit: 'contain' }} alt="" className="" />
                                    <IconButton onClick={() => setDrawer(true)}>
                                        <img src={Icons.menu} style={{ height: 25, width: 'auto', objectFit: 'contain' }} alt="" className="" />
                                    </IconButton>
                                </Stack>
                            </Stack>
                            :
                            <div style={{ width: '100%', ...CENTER }}>
                                <Stack
                                    width={isTablet ? '95%' : '85%'}
                                    direction={"row"}
                                    alignItems={"center"}
                                    justifyContent={"space-between"}
                                    sx={{ padding: isTablet ? '10px 0' : '20px 0' }}
                                >
                                    <img src={Icons.logo} onClick={() => navigate('/')} style={{ height: 40, width: 'auto', objectFit: 'contain' }} alt="" className="" />
                                    <Stack direction={'row'} gap={isTablet ? 0 : 1} alignItems={'center'} sx={{ color: '#000' }}>
                                        {
                                            Hrefs.map((item, index) => (
                                                <Button
                                                    key={index}
                                                    variant="text"
                                                    color="inherit"
                                                    onClick={(e) => Navs(e, item)}
                                                    sx={{ fontSize: isTablet ? 10 : undefined, textTransform: 'none', color: item.id === param.nav ? Colors.main : '#000' }}
                                                >{item.label}</Button>
                                            ))
                                        }
                                    </Stack>
                                    <Button
                                        variant={'contained'}
                                        color={"warning"}
                                        sx={{ backgroundColor: Colors.main, color: '#fff', fontWeight: '600', borderRadius: 30, textTransform: 'capitalize' }}
                                        startIcon={<img src={Icons.phoneLight} style={{ width: 20, height: 20, objectFit: 'contain' }} alt="" />}
                                    >Contact Us</Button>
                                </Stack>
                            </div>
                    }
                </Toolbar>
            </AppBar>
            {
                isMobile ?
                    <Drawer
                        sx={{
                            width: '100%',
                            flexShrink: 0,
                            "& .MuiDrawer-paper": {
                                width: '100%',
                                boxSizing: "border-box",
                            },
                        }}
                        variant={"temporary"}
                        anchor="right"
                        open={isDrawer}
                        onClose={toggleDrawer}
                    >
                        <Stack direction={'column'} gap={3} padding={2}>
                            <Stack direction={'row'} width={'100%'} alignItems={'center'} justifyContent={'space-between'}>
                                <img src={Icons.logo} onClick={() => navigate('/')} style={{ height: 40, width: 'auto', objectFit: 'contain' }} alt="" className="" />
                                <IconButton onClick={() => setDrawer(false)}>
                                    <Icon fontSize={'large'} sx={{ color: '#000' }}>close</Icon>
                                </IconButton>
                            </Stack>
                            <Stack direction={'column'} gap={1} sx={{ color: '#000' }}>
                                {
                                    Hrefs.map((item, index) => (
                                        <Stack key={index} direction={'column'} gap={1}>
                                            <Button variant="text" color="inherit" onClick={(e) => Navs(e, item)}>
                                                <Stack direction={'row'} gap={1} width={'100%'} justifyContent={'flex-start'} alignItems={'center'} paddingLeft={2}>
                                                    <Typography variant={'button'} color={item.id === param.nav ? Colors.main : '#000'}>{item.label}</Typography>
                                                </Stack>
                                            </Button>
                                        </Stack>
                                    ))
                                }
                            </Stack>
                        </Stack>
                    </Drawer>
                    :
                    null
            }
        </>
    );
};

export default NavigationBar;
